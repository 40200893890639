.insight {
  min-height: 100vh;
  margin: 0 auto;
  margin-top: 9rem;
  width: 90%;
  max-width: 55rem;
}

.insight h1 {
  font-size: 2.5rem;
}

.insight .summary {
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.insight .date {
  margin-top: 0.5rem;
  color: #9e9e9e;
}

.featuredImage {
  width: 100%;
  height: 25rem;
  background-position: top center;
  background-repeat: no-repeat;
  margin: 1rem 0 0.5rem 0;
}

.featuredImageCaption {
  color: #9e9e9e;
  text-align: center;
  font-style: italic;
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.divider {
  width: 70%;
  max-width: 40rem;
  border-bottom: thin solid rgb(0, 0, 0, 0.5);
  margin: 0 auto;
  margin-top: 3rem;
}

.subscribe {
  margin-bottom: 3rem;
}

.content p {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.content h1 {
  display: none;
}

.content h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
}

.content h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.content h4 {
  font-size: 1.1rem;
  margin-bottom: 0.25rem;
  margin-top: 1rem;
}

.content blockquote {
  width: 100%;
  border-left: thick solid rgb(0, 0, 0, 0.5);
  box-sizing: border-box;
  margin: 1rem 0;
  padding: 1rem 2rem;
}

.content blockquote p {
  margin: 0;
  font-style: italic;
}

.content a {
  color: var(--color-primary);
  text-decoration: underline;
}

.content img {
  width: 100%;
  max-width: 50rem;
  max-height: 30rem;
  margin: 2rem auto;
  object-fit: contain;
  display: block;
}

.content imagecaption {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #9e9e9e;
  font-style: italic;
  font-size: 0.9rem;
  margin-top: -1rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 1100px) {
  .insight {
    margin-top: 8rem;
  }

  .featuredImage {
    height: 15rem;
  }
}