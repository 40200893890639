.delayWrap {
  width: 100%;
  min-height: 100vh;
}

.featured {
  width: 72rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-top: 3rem;
  min-height: calc(100vh - 70vh - 6rem);
}

.featured .featuredWrap {
  width: 20rem;
}

.featured .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 4rem;
}

.featured .header p {
  font-size: 1.2rem;
  text-transform: uppercase;
}

.featured .header .icon {
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 1.2rem;
  flex-grow: 0;
  flex-shrink: 0;
}

.featured .featuredWrap:first-child .header .icon {
  width: 4rem;
  height: 4rem;
}

.featured .featuredWrap:first-child .header {
  margin-top: -0.25rem;
}

.featured .featuredWrap:first-child .description {
  margin-top: 2.75rem;
}

.featured .description {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 3rem;
}

.featured .description .arrow {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: -0.25rem !important;
}

.divider {
  height: 13rem;
  border-left: thin solid black;
}

.solutions {
  width: 90%;
  max-width: 80rem;
  margin: 4rem auto;
}

.solutions h2 {
  font-size: 2.5rem;
  text-transform: uppercase;
}

.solutions p {
  font-size: 1.4rem;
}

.solutions > div {
  width: 100%;
  margin: 1rem -0.5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

.trustTrackerHero {
  background-position: right;
  align-items: flex-end;
}

@media only screen and (max-width: 1100px) {
  .featured {
    display: block;
    width: 90%;
    padding-top: 0;
  }

  .featured .featuredWrap {
    border-bottom: thin solid black;
    padding: 1.5rem 0;
    margin: 0 auto;
  }

  .featured .header {
    width: 90%;
    font-size: 3.5rem;
  }

  .featured .featuredWrap:last-child {
    border-bottom: none;
  }

  .featured .header p {
    font-size: 1rem;
  }

  .featured .description {
    margin-top: 1rem;
  }

  .divider {
    display: none;
  }

  .solutions {
    margin: 2rem auto;
  }
  
  .solutions h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .solutions p {
    font-size: 1.2rem;
  }

  .trustTrackerHero {
    background-position: right;
    background-size: cover;
  }
}