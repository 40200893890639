.wrap {
  width: 90%;
  max-width: 60rem;
  min-height: 9rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  box-shadow: var(--box-shadow);
  border-radius: 0.5rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: 25% 71% 4%;
  align-items: center;
  padding-right: 0.5rem;
  cursor: pointer;
}

.image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.content {
  padding: 1rem;
}

.content h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.wrap:hover .content h2 {
  text-decoration: underline;
}

.content .date {
  margin-top: 0.5rem;
  color: #9e9e9e;
}

.arrow {
  font-size: 2.5rem !important;
  justify-self: flex-end;
}

@media only screen and (max-width: 1100px) {
  .wrap {
    display: block;
    padding-right: 0;
  }

  .image {
    height: 12rem;
  }

  .arrow {
    display: none !important;
  }
}