.solutionItem {
  position: relative;
  width: 24%;
  min-width: 19rem;
  min-height: 15.5rem;
  box-shadow: var(--box-shadow-hard);
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 2rem 0.5% 0 0.5%;
}

.solutionItem .header {
  background-color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 3.5rem;
  padding: 0.5rem 0.8rem;
}

.solutionItem .header h3 {
  color: white;
  font-size: 0.3em;
  margin-left: 0.8rem;
  text-transform: uppercase;
}

.solutionItem .description {
  width: 100%;
}

.solutionItem .description ul {
  padding-left: 1.5rem;
  padding-right: 0.5rem;
}

.solutionItem .description ul li {
  font-size: 1.1rem;
}

.readMore {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  padding: 0 0 1rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.readMore p {
  font-size: 1.1rem !important;
}

.solutionItem:hover .readMore p {
  text-decoration: underline;
}