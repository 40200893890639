.header {
  width: 100%;
  background-color: var(--color-primary);
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2.8rem;
  padding: 0.5rem 0;
  position: fixed;
  top: 4.3rem;
  left: 0;
  transition: 0.3s top;
  z-index: 999;
}

.header.isOnTop {
  top: 6.1rem;
}

.header h1 {
  font-size: 1.7rem;
  color: white;
  width: 90%;
  max-width: 50rem;
  margin: 0 auto;
}

.description {
  width: 100%;
  min-height: 100vh;
  padding-top: 10rem;
  padding-bottom: 3rem;
}

.content {
  width: 90%;
  max-width: 50rem;
  margin: 0 auto;
}

.content h1 {
  display: none;
}

.content h2 {
  font-size: 1.7rem;
  margin: 1.7rem 0 0.7rem 0;
}

.content h3 {
  font-size: 1.4rem;
  margin: 1.4rem 0 0.5rem 0;
}

.content h4 {
  font-size: 1rem;
  margin: 0.5rem 0;
  font-weight: 600;
}

.content p {
  font-size: 1rem;
  margin-bottom: 0.8rem;
}

.content ol, .content ul {
  margin: -0.25rem 0 1rem 0;
}