.wrap {
  width: 100%;
}

.item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2.5rem;
  margin-bottom: 0.25rem;
}

.item a, .item p {
  font-size: 1rem;
  margin-left: 0.5rem;
}

.item a:hover {
  text-decoration: underline;
}