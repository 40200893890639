.banner {
  position           : relative;
  width              : 100%;
  min-height         : 80vh;
  display            : flex;
  justify-content    : flex-start;
  align-items        : center;
  background-position: top center;
  background-size    : cover;
  background-repeat  : no-repeat;
}

.bannerText {
  width      : 100%;
  max-width  : 50rem;
  margin-left: 2.7%;
}

.banner h1 {
  color         : white;
  font-size     : 4rem;
  text-transform: uppercase;
}

.banner p {
  color     : white;
  font-size : 1.8rem;
  margin-top: 0.5em;
}

.banner button {
  color           : white;
  height          : 2.5rem;
  width           : 8rem;
  transition      : all 0.5s;
  position        : absolute;
  background-color: transparent;
  border          : 1px solid white;
  bottom          : 3rem;
}

.banner button::before {
  content   : '';
  position  : absolute;
  top       : 0;
  left      : 0;
  width     : 100%;
  height    : 100%;
  z-index   : 1;
  transition: all 0.5;
}

.banner button:hover::before {
  opacity  : 0;
  transform: scale(0.5, 0.5);
}

.banner button::after {
  content         : '';
  position        : absolute;
  top             : 0;
  left            : 0;
  width           : 100%;
  height          : 100%;
  z-index         : 1;
  opacity         : 0;
  background-color: rgba(255, 255, 255, 0.1);
  transition      : all 0.5s;
  transform       : scale(1.2, 1.2);
}

.banner button:hover {
  border     : 0rem;
  color      : black;
  cursor     : pointer;
  font-weight: 600;
}

.banner button:hover::after {
  border   : 0px;
  opacity  : 1;
  transform: scale(1, 1);
}

@media only screen and (max-width: 1100px) {
  .banner {
    min-height: 80vw;
    margin-top: 5rem;
  }

  .banner h1 {
    font-size: 10vw;
  }

  .banner p {
    font-size: 1.2rem;
  }

  .banner button {
    height     : 1.8rem;
    width      : 6.7rem;
    font-size  : small;
    z-index    : 101;
    color      : white;
    font-weight: 800;
    border     : none;
    animation  : bouncy 5s linear 100ms both;
  }
}


@media only screen and (max-width: 420px) {
  .banner a {
    position: absolute;
    right   : 7.6rem;
    bottom  : 1.8rem;
  }
}

@keyframes bouncy {
  0% {
    top: 0em
  }

  40% {
    top: 0em
  }

  43% {
    top: -0.9em
  }

  46% {
    top: 0em
  }

  48% {
    top: -0.4em
  }

  50% {
    top: 0em
  }

  100% {
    top: 0em;
  }
}