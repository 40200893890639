.footer {
  width: 100%;
}

.help {
  width: 100%;
  padding-bottom: 2.6rem;
  background: linear-gradient(to bottom right, #000000, #002060, #00B0F0);
  overflow: hidden;
}

.helpFadein {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5rem;
}

.helpFadein > div {
  margin-left: 1rem;
  margin-top: 2.6rem;
}

.help h3 {
  color: white;
  font-size: 1.25em;
}

.help p {
  color: white;
  font-size: 2rem;
  font-weight: 900;
}

.help p a {
  color: white;
  font-size: inherit;
  text-decoration: underline;
  text-decoration-color: var(--color-primary);
  text-decoration-thickness: 0.3rem;
}

.copy {
  width: 100%;
  text-align: right;
  padding: 0 1rem 1rem 0;
}

.copy p {
  font-size: 1rem;
  color: #4e4e4e;
  font-weight: 300;
}

.info {
  display: flex;
  justify-content: space-evenly;
  padding: 2rem 0 1rem 0;
  width: 90%;
  margin: 0 auto;
}

.info a:hover {
  text-decoration: underline;
}

.box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.box img {
  width: 9rem;
  margin-right: 1.5rem;
}

.box a {
  display: block;
}

.box .indent {
  margin-left: 0.7rem;
}

.subtitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.subtitle p {
  font-weight: 900;
  font-size: 1.2em;
}

.divider {
  margin: 2rem 0;
  border-left: thin solid rgb(0, 0, 0, 0.3);
}

@media only screen and (max-width: 1100px) {
  .help {
    padding-bottom: 7vw;
  }

  .helpFadein {
    font-size: 6vw;
  }

  .helpFadein > div {
    margin-left: 0;
    margin-top: 7vw;
  }

  .help p {
    color: white;
    font-size: 4.5vw;
  }

  .help p a {
    text-decoration-thickness: 0.2rem;
  }

  .copy {
    text-align: center;
    padding: 0;
    margin-bottom: 1rem;
  }

  .divider {
    display: none;
  }

  .info {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0 0 0;
  }

  .box {
    width: 100%;
    justify-content: center;
    margin-bottom: 2rem;
  }

  .box img {
    width: 8rem;
    margin-right: 1rem;
  }

  .box .indent {
    margin-left: 0.8rem;
  }

  .text.data {
    width: 12rem;
    margin-right: -1rem;
  }
  
  .subtitle {
    font-size: 1.2rem;
  }
}