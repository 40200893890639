.ourTeam {
  min-height: 100vh;
}

.teamWrap {
  width: 90%;
  max-width: 60rem;
  margin: 2rem auto;
  font-size: 1.2rem;
}

.teamWrap:first-child {
  margin-top: 3rem;
}

.teamWrap h2 {
  font-size: 1.8rem;
}

.teamWrap > div {
  width: 100%;
  display: grid;
  grid-template-columns: 31% 31% 31%;
  justify-content: space-between;
}

@media only screen and (max-width: 1100px) {
  .teamWrap > div {
    display: block;
  }
}