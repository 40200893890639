.insights {
  width: 100%;
  min-height: 100vh;
  margin-top: 4rem;
}

.loadMore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
  margin-bottom: 2rem;
}

.loadMore > div {
  cursor: pointer;
  text-align: center;
  font-size: 2rem;
}

.loadMore > div p {
  margin-bottom: 0.25rem;
  font-size: 1.3rem;
}

.arrow {
  transform: rotate(90deg);
  margin-right: 0 !important;
}
