.delay {
  width: 100%;
  min-height: 70vh;
}

.hero {
  background-position: top center;
}

.solutions {
  width: 90%;
  max-width: 80rem;
  margin: 3rem auto;
  margin-bottom: 5rem;
}

.solutions > div {
  width: 100%;
  margin: 1rem -0.5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1100px) {
  .solutions {
    margin: 2rem auto;
  }
}