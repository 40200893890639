.headline {
  width: 90%;
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;
  padding-top: 3rem;
}

.headline h3 {
  font-size: 2.5rem;
}

.headline > p {
  font-size: 1.2rem;
}

.emailInput {
  width: 60%;
  margin: 2rem auto 0 auto;
}

@media only screen and (max-width: 1100px) {
  .headline h3 {
    font-size: 2rem;
  }

  .headline > p {
    font-size: 1.1rem;
  }

  .emailInput {
    width: 90%;
    margin: 1rem auto 0 auto;
  }
}