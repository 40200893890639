.header {
  width           : 100%;
  background-color: var(--color-primary);
  color           : white;
  display         : flex;
  justify-content : flex-start;
  align-items     : center;
  font-size       : 2.8rem;
  padding         : 0.5rem 4%;
  position        : fixed;
  top             : 4.3rem;
  left            : 0;
  transition      : 0.3s top;
  z-index         : 999;
}

.header.isOnTop {
  top: 6.1rem;
}

.header h1 {
  font-size  : 0.6em;
  color      : white;
  margin-left: 1rem;
}

.description {
  width      : 100%;
  min-height : 100vh;
  padding-top: 13rem;
}

.description {
  width          : 100%;
  display        : flex;
  justify-content: center;
  align-items    : flex-start;
}

.content {
  width    : 50%;
  max-width: 50rem;
}

.exampleContainer {
  display       : flex;
  flex-direction: column;
  width         : 40%;
}

.example {
  display: flex;
  flex-direction: column;
  width      : 100%;
  max-width  : 40rem;
  margin-top: 2rem;
  margin-left: 2rem;
}

.explainerItem {
  width        : 100%;
  margin-bottom: 2rem;
}

.explainerItemHeader {
  width          : 100%;
  display        : flex;
  justify-content: flex-start;
  align-items    : center;
  font-size      : 4rem;
}

.explainerItemHeader>div {
  width      : 75%;
  margin-left: 1rem;
}

.explainerItemHeader>div h2 {
  font-size: 1.8rem;
}

.explainerItemHeader>div p {
  font-size: 1rem;
}

.explainerItemContent {
  width: 90%;
}

.gallery {
  margin: 3rem 0;
}

@media only screen and (max-width: 1100px) {
  .description {
    flex-wrap  : wrap;
    padding-top: 12rem;
  }

  .content,
  .example,
  .exampleContainer {
    width : 90%;
    margin: 0 auto;
  }

  .example img {
    max-width: 100%;
  }

  .title {
    width: 100%;
  }

  .title h1 {
    font-size: 1.4rem;
  }

  .explainerItemHeader>div h2 {
    font-size: 1.5rem;
  }

  .explainerItem:last-child {
    margin-bottom: 2rem;
  }

  .header {
    font-size: 9vw;
  }
}