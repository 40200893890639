.gallery {
  width: 100%;
  background-color: var(--color-primary-transparent);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 5rem;
  color: white;
  position: relative;
}

.gallery > div {
  padding: 2rem 0;
  max-width: calc(100% - 15rem);
  overflow: -moz-scrollbars-none;
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.gallery > div::-webkit-scrollbar {
  display: none;
}

.gallery img {
  height: 10rem;
  margin: 0 1rem;
  cursor: pointer;
  transition: transform 0.3s;
  border-radius: 0.5rem;
}

.gallery img:hover {
  transform: scale(1.05);
}

.arrowNext {
  margin-right: 0 !important;
  cursor: pointer;
}

.arrowPreviouse {
  transform: rotate(180deg);
  margin-right: 0 !important;
  cursor: pointer;
}

.fullSizeView {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  opacity: 0;
  background-color: var(--color-primary-transparent);
  backdrop-filter: blur(0.3rem);
  z-index: 99999;
  border-radius: 50%;
  transition: width 0.3s, height 0.3s, opacity 0.3s, border-radius 0.3s;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.fullSizeView.active {
  width: 100vw;
  height: 100vh;
  opacity: 1;
  border-radius: 0;
}

.fullSizeView .imgContainer {
  width: 70%;
  height: 70%;
  margin: 0 auto;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.fullSizeView p {
  color: white;
  position: absolute;
  left: 50%;
  top: 2rem;
  transform: translateX(-50%);
  width: 90%;
  text-align: center;
}

.closeIcon {
  font-size: 4rem;
  position: absolute;
  right: 2.5rem;
  top: 1.5rem;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 1100px) {
  .gallery {
    font-size: 3rem;
  }

  .gallery > div {
    max-width: 100%;
  }

  .fullSizeView .imgContainer {
    width: 90%;
    height: 90%;
  }

  .fullSizeView p {
    top: 4rem;
  }

  .closeIcon {
    top: unset;
    bottom: 1.5rem;
  }

  .arrowNext, .arrowPreviouse {
    display: none !important;
  }
}