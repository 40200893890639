.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 99;
  transition: 0.3s padding;
}

.logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 1.35rem;
}

.logo h1 {
  font-size: 1.7em;
  color: white;
}

.header.altColor {
  color: black;
  box-shadow: 0 0.1rem 0.3rem rgb(0, 0, 0, 0.4);;
}

.header.altColor .logo h1 {
  color: black;
}

.links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.links a {
  position: relative;
  font-size: 1.3rem;
  margin-right: 3rem;
  color: white;
  font-weight: 600;
}

.header.altColor .links a {
  color: black;
}

.links a::after {
  content: '';
  position: absolute;
  bottom: -0.2rem;
  left: 0;
  width: 0;
  height: 0.2rem;
  background-color: white;
  transition: opacity 300ms, width 300ms;
}

.links a:hover::after{
  width: 100%;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .avatar {
  background-color: transparent;
  border: 0.2rem solid white;
  margin-right: 0.5rem;
  width: 2.2rem;
  height: 2.2rem;
}

.header.altColor .login .avatar {
  border: 0.15rem solid black;
  color: black;
}

.altHeader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  bottom: -100%;
  left: 0;
  opacity: 0;
  transition: bottom 300ms, opacity 300ms;
  justify-content: center;
  align-items: center;
  display: flex;
  pointer-events: none;
}

.altHeader.active {
  bottom: 0;
  opacity: 1;
  pointer-events: unset;
}

.altHeader .logo h1 {
  color: white !important;
}

.menuIcon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
  font-size: 2rem;
  color: white;
}

.header.altColor .menuIcon {
  color: black;
}

.header.altColor .menuIcon.alt {
  color: white;
}

.solutionsLinkWrap {
  position: relative;
}

.popupMenu {
  width: 13rem;
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translate(-57%);
  z-index: 9999;
  opacity: 0;
  max-height: 0;
  transition: max-height 0.3s, opacity 0.3s;
  pointer-events: none;
  padding-top: 0.5rem;
}

.popupMenu > div {
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 1.2rem;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
}

.solutionsLinkWrap:hover .popupMenu {
  opacity: 1;
  max-height: 50vh;
  pointer-events: all;
}

.popupMenu > div a {
  font-size: 1rem;
  display: block;
  margin: 0.4rem auto;
  text-align: center;
  color: black;
}

@media only screen and (max-width: 1100px) {  
  .logo {
    font-size: 1.35rem;
  }

  .header .logo {
    visibility: hidden;
  }

  .altHeader .logo {
    visibility: visible;
  }

  .links {
    display: none;
  }

  .header {
    justify-content: center;
  }
}