.loader {
  width: 11rem;
  height: 3rem;
  position: relative;
}

.arrow {
  font-size: 2rem !important;
  animation-iteration-count: infinite;
  animation: move 3s infinite;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes move {
  0% { left: 0; transform: translateY(-50%) rotate(0);}
  25% { left: 80%; transform: translateY(-50%) rotate(0);}
  50% { left: 80%; transform: translateY(-50%) rotate(-180deg);}
  75% { left: 0; transform: translateY(-50%) rotate(-180deg);}
  100% { left: 0; transform: translateY(-50%) rotate(0);}
}