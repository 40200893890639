.container {
  width: 20rem;
  max-width: 70vw;
  font-size: 1.5rem;
  padding-left: 0.5rem;
}

.container a {
  margin-left: 0.25rem;
}

.loginWrap {
  width: 100%;
  padding: 1rem;
}

.input {
  margin-top: 0.8rem !important;
}

.button {
  margin-top: 0.8rem !important;
}

.contact {
  display: block;
  font-size: 1rem;
  margin-top: 2rem;
  color: var(--color-primary);
}

.login {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
}

.login .avatar {
  background-color: transparent;
  border: 0.15rem solid black;
  margin-right: 0.5rem;
  width: 2.2rem;
  height: 2.2rem;
  color: black;
}

.header.altColor .login .avatar {
  border: 0.15rem solid black;
  color: black;
}

.alert {
  margin-top: 2rem;
}

.loader {
  color: white;
}