.personItem {
  width: 100%;
  margin: 1rem 0 2rem 0;
  box-shadow: var(--box-shadow);
  border-radius: 0.5rem;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 12rem;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

.content {
  padding: 1rem 0.5rem;
}

.content .name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
}

.content .name h3 {
  text-transform: uppercase;
  font-size: 1.1rem;
  margin-left: 0.3rem;
}

.content .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.5rem;
  padding-left: 1.1rem;
  margin-bottom: 0.25rem;
}

.content .info p, .content .info a {
  font-size: 1rem;
}

.content .info .icon {
  margin-right: 0.5rem;
}

.details {
  width: 100%;
  font-size: 1rem;
  padding: 0 0 0 0.5rem;
}

.details > div ul {
  padding: 0.5rem 1.5rem;
  margin: 0;
}

@media only screen and (max-width: 1100px) {
  .personItem {
    width: 90%;
    max-width: 20rem;
    margin: 1rem auto 2rem auto;
  }
}