.content {
  width: 90%;
  max-width: 60rem;
  box-shadow: var(--box-shadow);
  margin: 3rem auto;
  display: grid;
  grid-template-columns: 45% 55%;
  border-radius: 0.5rem;
}

.content .map {
  width: 100%;
  background-image: url(../../assets/map.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.content > div {
  width: 100%;
  padding: 1rem 4rem 1rem 1.5rem;
}

.item {
  font-size: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.item a, .item p {
  font-size: 1.1rem;
  margin-left: 1rem;
}

.item a:hover {
  text-decoration: underline;
}

.item a address {
  font-style: normal;
}

@media only screen and (max-width: 1100px) {
  .content {
    display: flex;
    grid-template-columns: unset;
    flex-wrap: wrap;
  }

  .map {
    height: 30vh;
    order: -1;
    margin-bottom: 1rem;
  }

  .content > div {
    padding: rem 1.5rem;
  }
}